
/**
 * @license
 * Copyright Nico Schett. All Rights Reserved.
 */

/* tslint:disable */
// This file was automatically generated and should not be edited.

import { GraphqlResult } from "@schettnet/bridge/lib/types";
import gql from "graphql-tag";

import BridgeDrop from "./index";

// @ts-ignore
import { pagesIndexTreeQuery,pagesIndexTreeQueryVariables,meQuery,meQueryVariables,myJaenAccountQuery,myJaenAccountQueryVariables,readSnekSettingsQuery,readSnekSettingsQueryVariables,pagesQuery,pagesQueryVariables,pageQuery,pageQueryVariables } from "./types.gen";

// ====================================================
// Bridge operation: doPagesIndexTree
// ====================================================

export const doPagesIndexTreeQuery = async (
    variables: pagesIndexTreeQueryVariables
): Promise<GraphqlResult<pagesIndexTreeQuery>> => {

    const document = gql`fragment pageTreeFields_pagesIndexTreeQuery on PageTree { __typename
tree
hasUpdated
checksum}
query pagesIndexTreeQuery($checksum: String!){
pagesIndexTree(checksum: $checksum) { ...pageTreeFields_pagesIndexTreeQuery}
}
`;

    return await BridgeDrop.bridge.session.query<pagesIndexTreeQuery>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doMe
// ====================================================

export const doMeQuery = async (
    variables: meQueryVariables
): Promise<GraphqlResult<meQuery>> => {

    const document = gql`fragment userTypeFields_meQuery on UserType { __typename
id
username}
query meQuery($token: String){
me(token: $token) { ...userTypeFields_meQuery}
}
`;

    return await BridgeDrop.bridge.session.query<meQuery>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doMyJaenAccount
// ====================================================

export const doMyJaenAccountQuery = async (
    variables: myJaenAccountQueryVariables
): Promise<GraphqlResult<myJaenAccountQuery>> => {

    const document = gql`fragment userTypeFields_myJaenAccountQuery on UserType { __typename
id
username}
fragment jaenPublishFormSubmissionFields_myJaenAccountQuery on JaenPublishFormSubmission { __typename
id}
fragment jaenEmailFormSubmissionFields_myJaenAccountQuery on JaenEmailFormSubmission { __typename
id}
fragment jaenAccountTypeFields_myJaenAccountQuery on JaenAccountType { __typename
id
user { ...userTypeFields_myJaenAccountQuery}
encryptionToken
gitUser
publishSubmissions { ...jaenPublishFormSubmissionFields_myJaenAccountQuery}
emailSubmissions { ...jaenEmailFormSubmissionFields_myJaenAccountQuery}
isSnekSupervisor}
query myJaenAccountQuery($token: String){
myJaenAccount(token: $token) { ...jaenAccountTypeFields_myJaenAccountQuery}
}
`;

    return await BridgeDrop.bridge.session.query<myJaenAccountQuery>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doReadSnekSettings
// ====================================================

export const doReadSnekSettingsQuery = async (
    variables: readSnekSettingsQueryVariables
): Promise<GraphqlResult<readSnekSettingsQuery>> => {

    const document = gql`fragment snekSettingsFields_readSnekSettingsQuery on SnekSettings { __typename
id}
query readSnekSettingsQuery($id: ID!){
readSnekSettings(id: $id) { ...snekSettingsFields_readSnekSettingsQuery}
}
`;

    return await BridgeDrop.bridge.session.query<readSnekSettingsQuery>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doPages
// ====================================================

export const doPagesQuery = async (
    variables: pagesQueryVariables
): Promise<GraphqlResult<pagesQuery>> => {

    const document = gql`fragment pageInterfaceFields_pagesQuery on PageInterface { __typename
id
url
urlPath
slug
depth
pageType
title
seoTitle
seoDescription
showInMenus
contentType
lastPublishedAt... on JaenRegistrationFormPage{...jaenRegistrationFormPageFields_pagesQuery}... on JaenPublishFormPage{...jaenPublishFormPageFields_pagesQuery}... on JaenEmailFormPage{...jaenEmailFormPageFields_pagesQuery}... on Page{...pageFields_pagesQuery}}
fragment jaenRegistrationFormPageFields_pagesQuery on JaenRegistrationFormPage { __typename
id
depth
title
slug
contentType
urlPath
seoTitle
showInMenus
lastPublishedAt
url
pageType
seoDescription}
fragment jaenPublishFormPageFields_pagesQuery on JaenPublishFormPage { __typename
id
depth
title
slug
contentType
urlPath
seoTitle
showInMenus
lastPublishedAt
url
pageType
seoDescription}
fragment jaenEmailFormPageFields_pagesQuery on JaenEmailFormPage { __typename
id
depth
title
slug
contentType
urlPath
seoTitle
showInMenus
lastPublishedAt
url
pageType
seoDescription}
fragment userTypeFields_pagesQuery on UserType { __typename
id
username}
fragment jaenRegistrationFormSubmissionFields_pagesQuery on JaenRegistrationFormSubmission { __typename
id}
fragment jaenPublishFormSubmissionFields_pagesQuery on JaenPublishFormSubmission { __typename
id}
fragment jaenEmailFormSubmissionFields_pagesQuery on JaenEmailFormSubmission { __typename
id}
fragment pageFields_pagesQuery on Page { __typename
id
path
depth
numchild
translationKey
title
draftTitle
slug
contentType
live
hasUnpublishedChanges
urlPath
owner { ...userTypeFields_pagesQuery}
seoTitle
showInMenus
searchDescription
goLiveAt
expireAt
expired
locked
lockedAt
lockedBy { ...userTypeFields_pagesQuery}
firstPublishedAt
lastPublishedAt
latestRevisionCreatedAt
aliasOf{ __typename }
aliases{ __typename }
jaenregistrationformpage { ...jaenRegistrationFormPageFields_pagesQuery}
jaenregistrationformsubmissionSet { ...jaenRegistrationFormSubmissionFields_pagesQuery}
jaenpublishformpage { ...jaenPublishFormPageFields_pagesQuery}
jaenpublishformsubmissionSet { ...jaenPublishFormSubmissionFields_pagesQuery}
jaenemailformpage { ...jaenEmailFormPageFields_pagesQuery}
jaenemailformsubmissionSet { ...jaenEmailFormSubmissionFields_pagesQuery}
url
pageType
seoDescription}
query pagesQuery($token: String, $limit: PositiveInt, $offset: PositiveInt, $order: String, $searchQuery: String, $id: ID){
pages(token: $token, limit: $limit, offset: $offset, order: $order, searchQuery: $searchQuery, id: $id) { ...pageInterfaceFields_pagesQuery}
}
`;

    return await BridgeDrop.bridge.session.query<pagesQuery>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doPage
// ====================================================

export const doPageQuery = async (
    variables: pageQueryVariables
): Promise<GraphqlResult<pageQuery>> => {

    const document = gql`fragment pageInterfaceFields_pageQuery on PageInterface { __typename
id
url
urlPath
slug
depth
pageType
title
seoTitle
seoDescription
showInMenus
contentType
lastPublishedAt... on JaenRegistrationFormPage{...jaenRegistrationFormPageFields_pageQuery}... on JaenPublishFormPage{...jaenPublishFormPageFields_pageQuery}... on JaenEmailFormPage{...jaenEmailFormPageFields_pageQuery}... on Page{...pageFields_pageQuery}}
fragment jaenRegistrationFormPageFields_pageQuery on JaenRegistrationFormPage { __typename
id
depth
title
slug
contentType
urlPath
seoTitle
showInMenus
lastPublishedAt
url
pageType
seoDescription}
fragment jaenPublishFormPageFields_pageQuery on JaenPublishFormPage { __typename
id
depth
title
slug
contentType
urlPath
seoTitle
showInMenus
lastPublishedAt
url
pageType
seoDescription}
fragment jaenEmailFormPageFields_pageQuery on JaenEmailFormPage { __typename
id
depth
title
slug
contentType
urlPath
seoTitle
showInMenus
lastPublishedAt
url
pageType
seoDescription}
fragment userTypeFields_pageQuery on UserType { __typename
id
username}
fragment jaenRegistrationFormSubmissionFields_pageQuery on JaenRegistrationFormSubmission { __typename
id}
fragment jaenPublishFormSubmissionFields_pageQuery on JaenPublishFormSubmission { __typename
id}
fragment jaenEmailFormSubmissionFields_pageQuery on JaenEmailFormSubmission { __typename
id}
fragment pageFields_pageQuery on Page { __typename
id
path
depth
numchild
translationKey
title
draftTitle
slug
contentType
live
hasUnpublishedChanges
urlPath
owner { ...userTypeFields_pageQuery}
seoTitle
showInMenus
searchDescription
goLiveAt
expireAt
expired
locked
lockedAt
lockedBy { ...userTypeFields_pageQuery}
firstPublishedAt
lastPublishedAt
latestRevisionCreatedAt
aliasOf{ __typename }
aliases{ __typename }
jaenregistrationformpage { ...jaenRegistrationFormPageFields_pageQuery}
jaenregistrationformsubmissionSet { ...jaenRegistrationFormSubmissionFields_pageQuery}
jaenpublishformpage { ...jaenPublishFormPageFields_pageQuery}
jaenpublishformsubmissionSet { ...jaenPublishFormSubmissionFields_pageQuery}
jaenemailformpage { ...jaenEmailFormPageFields_pageQuery}
jaenemailformsubmissionSet { ...jaenEmailFormSubmissionFields_pageQuery}
url
pageType
seoDescription}
query pageQuery($id: ID, $slug: String, $token: String, $contentType: String){
page(id: $id, slug: $slug, token: $token, contentType: $contentType) { ...pageInterfaceFields_pageQuery}
}
`;

    return await BridgeDrop.bridge.session.query<pageQuery>(
      document,
      variables
    );
}
