/**
 * @license
 *
 * SPDX-FileCopyrightText: Copyright © 2021 snek.at
 * SPDX-License-Identifier: EUPL-1.2
 *
 * Use of this source code is governed by an EUPL-1.2 license that can be found
 * in the LICENSE file at https://snek.at/license
 */
import styled from 'styled-components'

export const PdfViewer = styled.div`
  height: 100%;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  background: black;
  color: white;
  font-size: 200%;
`
