/**
 * @license
 *
 * SPDX-FileCopyrightText: Copyright © 2021 snek.at
 * SPDX-License-Identifier: EUPL-1.2
 *
 * Use of this source code is governed by an EUPL-1.2 license that can be found
 * in the LICENSE file at https://snek.at/license
 */
import {combineReducers} from 'redux'

import editingDataLayerReducer from './editingReducer'
import valuesReducer from './valuesReducer'
import workingDataLayerReducer from './workingReducer'

const dataLayerReducer = combineReducers({
  editing: editingDataLayerReducer,
  working: workingDataLayerReducer,
  values: valuesReducer
})

export default dataLayerReducer
